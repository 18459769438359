import styled, { css } from 'styled-components'

export const Wrapper = styled.header`
  ${({ theme }) => css`
    width: 100%;
    height: 82px;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8% 0 8%;

    background: ${theme.color.BLACK};

    img {
      height: 33%;
    }

    .button-container {
      width: 100%;

      display: flex;
      justify-content: right;
    }

    button {
      min-width: 140px;
      min-height: 26px;

      display: flex;
      align-items: center;

      margin: 2%;

      border-radius: 10px;
      border: none;

      font-size: 0.6rem;
      font-weight: 700;

      filter: drop-shadow(0px 2px 2px ${theme.color.WHITE});

      cursor: pointer;

      &:hover {
        filter: none;
        box-shadow: 0px 2px ${theme.color.GREY300};
      }

      img {
        display: inline-block;
        margin: 0px 10px 0px -5px;
        height: 30px;
        weight: auto;
      }
    }

    .help {
      display: flex;
      justify-content: center;
      background-color: ${theme.color.SECOUNDARY};
      padding: 0 0.5rem;
    }

    span {
      padding: 3px 10px;
      margin-right: 4%;
      margin-left: 4%;
    }

    @media (max-width: 480px) {
      button {
        min-width: 8%;
        min-height: 8%;
        margin-left: 12%;
      }

      span {
        display: none;
        padding: 1%;
      }
    }
  `}
`
