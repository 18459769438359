import Header from '../../components/Join/Header'
import * as Style from './styles'
import Form from '../../components/Join/Form'
import Input from 'components/Join/Input'
import { useContext, useEffect, useState } from 'react'
import router from 'next/router'
import { useAuthentication } from 'hooks/useAuthentication'
import { log } from '../../utils/Utils'
import Button from 'components/Join/Button'
import { TokenContext } from 'context/TokenContext'
import Head from 'next/head'
class Data {
  constructor(email?: string, password?: string) {
    this.email = email || ''
    this.password = password || ''
  }
  email: string
  password: string
}

export default function Login() {
  const [data, setData] = useState<Data>(new Data())
  const [
    loading,
    getAccessToken,
    authSuccess,
    authError,
    authStatusCode,
    accessToken,
  ] = useAuthentication()

  const [emailIsValid, setEmailIsValid] = useState<boolean>(true)
  const [passwordIsValid, setPasswordIsValid] = useState<boolean>(true)

  const { validateToken } = useContext(TokenContext)

  const onSubmit = (e: any) => {
    log('submit', data)

    let isValid = true
    if (data.email == undefined || data.email.trim() === '') {
      isValid = false
      setEmailIsValid(false)
    }

    if (data.password == undefined || data.password.trim() === '') {
      isValid = false
      setPasswordIsValid(false)
    }

    if (isValid) {
      getAccessToken(data.email, data.password)
    }
  }

  useEffect(() => {
    log('AuthSuccess', authSuccess, accessToken)
    if (authSuccess && accessToken) {
      validateToken(accessToken).then((userDetails) => {
        if (!userDetails.token) {
          router.push('/login')
        } else {
          if (userDetails.hasProfiles) {
            router.push('/profile/manage')
            return
          }

          if (!userDetails.onboardingCompleted) {
            router.push('/onboarding')
          } else {
            router.push('/home')
          }
        }
      })
    }
  }, [authSuccess, accessToken])

  const onChange = (e: any) => {
    setData((prev) => {
      if (e.target.name === 'email') {
        prev.email = e.target.value
      }
      if (e.target.name === 'password') {
        prev.password = e.target.value
      }
      return prev
    })
  }

  const onFocus = (e: any) => {
    setEmailIsValid(true)
    setPasswordIsValid(true)
  }

  const handleForgotPasswordRedirect = () => {
    router.push('/join/forgot-password')
  }

  return (
    <>
      <Style.Wrapper>
        <Head>
          <title>Conquer English | Área do Aluno</title>
          <meta name="title" content="Conquer English | Área do Aluno"></meta>
          <meta
            name="description"
            content="Faça login com seu e-mail e senha e acesse a área do aluno do Conquer English."
          ></meta>

          {/* Open Graph / Facebook */}
          <meta property="og:type" content="website"></meta>
          <meta
            property="og:url"
            content="https://ingles.escolaconquer.com.br/login"
          ></meta>
          <meta
            property="og:title"
            content="Conquer English | Área do Aluno"
          ></meta>
          <meta
            property="og:description"
            content="Faça login com seu e-mail e senha e acesse a área do aluno do Conquer English."
          ></meta>
          <meta property="og:image" content=""></meta>

          {/*  Twitter  */}
          <meta property="twitter:card" content="summary_large_image"></meta>
          <meta
            property="twitter:url"
            content="https://ingles.escolaconquer.com.br/login"
          ></meta>
          <meta
            property="twitter:title"
            content="Conquer English | Área do Aluno"
          ></meta>
          <meta
            property="twitter:description"
            content="Faça login com seu e-mail e senha e acesse a área do aluno do Conquer English."
          ></meta>
          <meta property="twitter:image" content=""></meta>
        </Head>
        <Header />
        <section>
          <div className="form-content">
            <Form title={<>Entrar na conta</>}>
              <div className="form-children">
                <Input
                  name="email"
                  type="email"
                  placeholder="seuemail@email.com"
                  isValid={emailIsValid}
                  onChange={onChange}
                  onFocus={onFocus}
                >
                  E-mail
                </Input>

                <Input
                  name="password"
                  type="password"
                  placeholder="*******"
                  isValid={passwordIsValid}
                  onChange={onChange}
                  onFocus={onFocus}
                >
                  Senha
                </Input>
                <p
                  className="text-forget-password"
                  onClick={handleForgotPasswordRedirect}
                >
                  Precisa criar ou Redefinir sua senha?
                </p>

                {/* <p
                  className="text-forget-password"
                  onClick={handleNotStudentYet}
                >
                  Ainda não é aluno?
                </p> */}

                <Button
                  onClick={(e) => onSubmit(e)}
                  disabled={loading || authSuccess}
                >
                  {loading || authSuccess ? 'ENTRANDO...' : 'ENTRAR'}
                </Button>

                {authError && (
                  <p className="invalid-credentials">Credenciais inválidas.</p>
                )}
              </div>
            </Form>
          </div>
          <div className="img-content">
            <div className="welcome-text">
              <div>Welcome to</div>
              <div>
                <span>Conquer</span>
              </div>
              <div>
                <span>English</span>
              </div>
            </div>
          </div>
        </section>
      </Style.Wrapper>
    </>
  )
}
