import * as Style from './styles'

import router from 'next/router'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons'

const Header = () => {
  const logo = '/logo-conquer-english-new-brand.png'

  function handleConquerPlus() {
    router.push(`https://conquerplus.com.br/login`)
  }

  function handleHelp() {
    router.push(String(process.env.NEXT_PUBLIC_HELP_URL))
  }

  return (
    <Style.Wrapper>
      <img src={logo} />
      <div className="button-container">
        <button className="help" onClick={() => handleHelp()}>
          <span>Precisa de ajuda?</span>
          <FontAwesomeIcon icon={faQuestionCircle} size={'2x'} />
        </button>
        <button onClick={() => handleConquerPlus()}>
          <span>Ir para o Conquer Plus</span>
          <img src="/conquer-logo-preto.png" alt="conquer plus logo" />
        </button>
      </div>
    </Style.Wrapper>
  )
}

export default Header
