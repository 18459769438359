import { log } from '../utils/Utils'
import axios from 'axios'
import { useState } from 'react'

export const useAuthentication = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [authSuccess, setAuthSuccess] = useState<boolean>(false)
  const [authError, setAuthError] = useState<boolean>(false)
  const [authStatusCode, setAuthStatusCode] = useState<number>()
  const [accessToken, setAccessToken] = useState<string>('')

  const getAccessToken = async (email: string, password: string) => {
    setLoading(true)
    setAuthError(false)

    setTimeout(async () => {
      // todo remover timeout
      setLoading(false)
      try {
        const userAgent = window?.navigator?.userAgent
        const resp = await axios.post(
          `${process.env.ENGLISH_BACKEND_URL}/login`,
          {
            email: email,
            password: password,
            userAgent: userAgent,
          }
        )
        log('useAuthentication::RESPONSE', resp)
        if (resp?.status === 200) {
          setAuthSuccess(true)
        }

        setAuthStatusCode(resp?.status)
        setAccessToken(resp?.data?.token)
      } catch (err: any) {
        log('AuthErr:', err)
        setLoading(false)
        setAuthError(true)
        setAuthStatusCode(err.response?.status)
        setAuthSuccess(false)

        setTimeout(() => {
          setAuthError(false)
        }, 10000)
      }
    }, 1000)
  }

  return [
    loading,
    getAccessToken,
    authSuccess,
    authError,
    authStatusCode,
    accessToken,
  ] as const
}
