import styled, { css } from 'styled-components'

export const Button = styled.button`
  ${({ theme }) => css`
    width: 100%;
    height: 10%;
    min-height: 48px;

    border: none;
    border-radius: 10px;

    font-family: ${theme.font.family.JOIN};
    font-weight: ${theme.font.weight.BOLD};
    font-size: 1.13rem;

    color: ${theme.color.WHITE};
    background: ${theme.color.PRIMARY};

    margin-top: 2%;
    margin-bottom: 2%;

    cursor: pointer;

    &:hover {
      filter: drop-shadow(0px 0px 2px ${theme.color.BLUE});
    }

    &:disabled {
      opacity: 0.7;
    }
  `}
`
