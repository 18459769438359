import * as Style from './styles'

interface InputProps {
  children?: string
  name: string
  type: string
  placeholder?: string
  required?: boolean
  isValid?: boolean
  onChange?: (e: any) => void
  onFocus?: (e: any) => void
}

const Input = (props: InputProps) => {
  return (
    <Style.Wrapper>
      <label>{props.children}</label>
      <input
        name={props.name}
        type={props.type}
        placeholder={props.placeholder}
        required={props.required}
        onChange={props.onChange}
        onFocus={props.onFocus}
      />
      {props.isValid ? (
        <></>
      ) : (
        <span className="phone-invalid">
          * Campo de preenchimento obrigatório
        </span>
      )}
    </Style.Wrapper>
  )
}

export default Input
