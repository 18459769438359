import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    margin-bottom: 4%;

    label {
      font-family: ${theme.font.family.JOIN};
      font-size: 0.9rem;
      font-weight: ${theme.font.weight.REGULAR};
    }

    input {
      width: 100%;
      margin-top: 0;
      margin-bottom: 0;

      background: ${theme.color.GREY_INPUT};
      color: ${theme.color.WHITE};
      border-color: ${theme.color.GREY300};

      &:focus {
        background: ${theme.color.GREY100};
        color: ${theme.color.BLACK};

        outline: 0;
        border-color: ${theme.color.ELETRIC_VIOLET} !important;
      }
    }

    span {
      font-family: ${theme.font.family.JOIN};
      font-size: 0.8rem;
      color: ${theme.color.YELLOW};
    }
  `}
`
