import styled, { css } from 'styled-components'

export const Form = styled.form`
  ${({ theme }) => css`
    max-height: calc(100vh - 82px);

    width: 60%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-radius: 12px;
    padding: 4%;

    color: ${theme.color.WHITE};
    background: ${theme.color.MAIN_BG};

    h1 {
      font-family: ${theme.font.family.JOIN};
      font-weight: ${theme.font.weight.REGULAR};
    }

    input {
      width: 91%;
      height: 7%;
      margin: 4% 0 4% 0;
      padding: 4%;

      border-radius: 6px;

      outline: none;

      :focus {
        border-color: ${theme.color.PRIMARY};
      }
    }

    @media (max-width: 480px) {
      width: 90%;
      min-height: 50%;
    }
  `}
`
