import styled, { css } from 'styled-components'

export const Wrapper = styled.main`
  ${({ theme }) => css`
    width: 100vw;
    height: 100vh;

    section {
      width: 100%;
      height: calc(100vh - 82px);
      background-image: url('/img/background_join.png');
      display: flex;

      .form-children {
        width: 90%;
      }

      .form-content {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        order: 0;
      }

      .text-forget-password {
        font-size: 0.8rem;
        text-decoration: underline;
        text-align: right;
        margin-bottom: 25px;
        cursor: pointer;
      }

      .img-content {
        width: 50%;

        display: flex;
        justify-content: center;
        align-items: center;
      }

      .welcome-text {
        font-size: 3.5rem;
        font-weight: bold;
        font-family: Prompt, sans serif;
        display: flex;
        flex-direction: column;

        div {
          color: ${theme.color.WHITE};
          line-height: 3.7rem;
        }
        span {
          color: ${theme.color.SECOUNDARY};
        }
      }
    }

    .invalid-credentials {
      padding: 25px 0 25px 0;
      color: ${theme.color.YELLOW};
    }

    .inputValidation {
      font-family: ${theme.font.family.JOIN};
      font-size: 1rem;
      color: ${theme.color.YELLOW};
      top: -10px;
      position: relative;
    }

    @media (max-width: 480px) {
      width: 100vw;
      section {
        flex-direction: column;
      }

      .welcome-text {
        flex-direction: row !important;
        font-size: 1.5rem !important;
        div {
          margin-right: 5px !important;
        }
      }

      .img-content {
        min-height: 20px !important;
        width: 100% !important;
      }

      .form-content {
        width: 100% !important;
        height: 60% !important;
        order: 1 !important;
      }
    }
  `}
`
