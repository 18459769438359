import * as Style from './styles'

interface ButtonProps {
  children: string
  disabled?: boolean
  onClick?: (e: any) => void
}

const Button = ({ children, onClick, disabled }: ButtonProps) => {
  const handleButtonClick = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    if (onClick) {
      onClick(e)
    }
  }

  return (
    <Style.Button onClick={handleButtonClick} disabled={disabled}>
      {children}
    </Style.Button>
  )
}

export default Button
