import * as Style from './styles'

interface FormProps {
  title: JSX.Element
  children?: JSX.Element
}

const Form = ({ title, children }: FormProps) => {
  return (
    <>
      <Style.Form>
        <h1>{title}</h1>
        {children}
      </Style.Form>
    </>
  )
}

export default Form
